import React from 'react';
import {useState} from 'react'
import { FormControl, Checkbox, FormControlLabel, Box, Button } from '@mui/material';
import { exportTranscripts } from '../backend/api';

const ExportForm = ({transcriptId}) => {

    const [exportSRT, setExportSRT] = useState(false)
    const [exportVTT, setExportVTT] = useState(false)
    const [exportDOCX, setExportDOCX] = useState(false)
    const [exportTXT, setExportTXT] = useState(false)
    const [exportSpeakers, setExportSpeakers] = useState(false)
    const [exportTimeCodes, setExportTimeCodes] = useState(false)

    const downloadFile = (file) => {
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = file;
        link.download = file.name;

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 0);
    }


    const handleExportSubmit = async() => {
        const options = {
            'formats': {
                'srt': exportSRT.toString(),
                'vtt': exportVTT.toString(),
                'docx': exportDOCX.toString(),
                'txt': exportTXT.toString()
            },
            'fields': {
                'Speakers': exportSpeakers.toString(),
                'TimeCodes': exportTimeCodes.toString()
            }
        }

        const fileUrl = await exportTranscripts(transcriptId, options)
        console.log(options)
        downloadFile(fileUrl)
    }

    return (
            <Box className="shadow-lg">
                <div className="w-full">
                    <FormControl>
                        <div className="w-full">
                            <FormControlLabel
                                label=".SRT"
                                control={<Checkbox/>}
                                onChange={() => {if(exportSRT === false) {setExportSRT(true)} else {setExportSRT(false)}}} />
                            <FormControlLabel
                                label=".VTT"
                                control={<Checkbox/>}
                                onChange={() => {if(exportVTT === false) {setExportVTT(true)} else {setExportVTT(false)}}} />
                        </div>
                        <div>
                            <FormControlLabel
                                label=".DOCX"
                                control={<Checkbox/>}
                                onChange={() => {if(exportDOCX === false) {setExportDOCX(true)} else {setExportDOCX(false)}}} />
                            <FormControlLabel
                                label=".TXT"
                                control={<Checkbox/>}
                                onChange={() => {if(exportTXT === false) {setExportTXT(true)} else {setExportTXT(false)}}} />
                        </div>
                        <div>
                            <FormControlLabel
                                label="Speakers"
                                control={<Checkbox/>}
                                onChange={() => {if(exportSpeakers === false) {setExportSpeakers(true)} else {setExportSpeakers(false)}}} />
                            <FormControlLabel
                                label="Time Codes"
                                control={<Checkbox/>}
                                onChange={() => {if(exportTimeCodes === false) {setExportTimeCodes(true)} else {setExportTimeCodes(false)}}} />
                        </div>
                        <div>
                            <Button onClick={handleExportSubmit}>Submit</Button>
                        </div>
                    </FormControl>
                </div>
            </Box>
    )
}
export default ExportForm