import React from 'react'
import { Typography } from '@mui/material'
import LoginForm from '../components/LoginForm';
import { checkAuth } from '../backend/authenticate';

const Home = () => {
    const auth = checkAuth()

    if (!auth) {
        return (
                <div className='h-screen flex items-center justify-center'>
                    <div className="grid grid-cols-1">
                        <Typography variant='h3'>Welcome</Typography>
                        <div className='homeButtons'>
                            <LoginForm></LoginForm>
                        </div>
                    </div>
                </div>
        )
    } else {
        window.location.href="/dashboard"
    }
}

export default Home