import React, { useEffect } from 'react'
import { BrowserRouter,Routes, Route,Navigate  } from 'react-router-dom'
import Dashboard from './pages/Dashboard';
import Editor from './pages/Editor';

import './components/css/app.css';
import userpool from './userpool';
import Home from './pages/Home';

function App() {

  useEffect(()=>{
    let user=userpool.getCurrentUser();
      if(user){
        <Navigate to="/dashboard" replace />
      }
  },[]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path='/editor' element={<Editor/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
