import React, {useState, useEffect} from "react";
import {Box} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import { fetchTranscriptions, deleteTranscript, getCurrentUserSession } from "../backend/api";
import DeleteModal from "./DeleteModal";

const RecentProjects = (deleteOpen) => {
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [transcriptId, setTranscriptId] = useState("")

    const handleClose = () => setOpen(false);

    const POLLING_INTERVAL = 5000;

    const openTranscript = (transcriptId, transcriptStatus) => {
        if (transcriptStatus === "COMPLETE") {
            window.location.href = "editor?transcriptionId="+transcriptId;
        } else {
            alert("This transcript has not finished processing as yet");
        }
    }

    const getProjectStatus = (project) => {
        const now = new Date().getTime() / 1000;
        if (project.TranscriptStatus === "PENDING" && (now - project.Created >= 1800)) {
            return "FAILED"
        }

        return project.TranscriptStatus
    }

    const fetchData = async() => {
        const data = await(fetchTranscriptions())
        if(Array.isArray(data)) {
            setProjects(data);
        }
    }

    const handleTranscriptDelete = (transcriptId) => {
        setTranscriptId(transcriptId)
        console.log("Deleting transcript " + transcriptId)
        setOpen(true)
    }

    useEffect(() => {(async () => {
        try{
            if (getCurrentUserSession() != false) {
                fetchData()
                setInterval(()=>{
                    try{
                        fetchData()
                    } catch(error) {
                        console.log(error)
                    }
                }, POLLING_INTERVAL)
            }
        } catch(error) {
            console.log(error)
        }
        })()
    }, [])

    if (projects != []) {
        return (<div>
                    <h2>
                        Recent Projects
                    </h2>
                <div>
                {projects.map((project) => (
                    <div className="transcript" key={project.id}>
                        <Box
                            sx={{
                                color: "white",
                                width: 1,
                                fontSize: 18,
                                marginTop: "10px",
                                padding: "40px",
                                cursor: 'pointer'
                            }}>
                                <div className="flex">
                                    <div className="flex w-full" onClick={() => openTranscript(project.id, project.TranscriptStatus)}>
                                        <Box sx={{
                                            backgroundColor: "gray",
                                            padding: '40px',
                                            borderRadius: '40px'
                                        }}>
                                        <AudioFileRoundedIcon fontSize="large" titleAccess="Media File"></AudioFileRoundedIcon></Box>
                                        <div className="items-center w-full p-10">{project.Title} - {getProjectStatus(project)}</div>
                                    </div>
                                    <div className="items-center p-10">
                                        <DeleteForeverIcon
                                            sx={{cursor: 'pointer'}}
                                            fontSize="large"
                                            titleAccess="Delete"
                                            onClick={() => handleTranscriptDelete(project.id)}>
                                        </DeleteForeverIcon>
                                    </div>
                                </div>
                        </Box>
                        <DeleteModal handleClose={handleClose} open={open} setOpen={setOpen} transcriptId={transcriptId} deleteTranscript={deleteTranscript}></DeleteModal>
                    </div>
                ))}
                </div>
        </div>)
    } else {
        return (
            <div>
                <h2>
                    Recent Projects
                </h2>
            </div>
        )
    }
}

export default RecentProjects;