import { Modal, Box, Typography } from "@mui/material";

const DeleteModal = ({handleClose, open, setOpen, transcriptId, deleteTranscript}) => {
    const style = {
        position: 'absolute',
        color: '#000',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const triggerDelete = async() => {
        deleteTranscript(transcriptId);
        setOpen(false);
    }

    return <Modal
                open={open}
                onClose={() => handleClose} 
                aria-labelledby="Delete transcript"
                aria-describedby="Delete transcript"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete this transcript?
                        </Typography>
                        <Box onClick={handleClose}>Close</Box>
                        <Box onClick={triggerDelete}>Okay</Box>
                    </Box>
                </Modal>
}

export default DeleteModal;