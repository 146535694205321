import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import SideNav from '../components/SideNav';
import RecentProjects from '../components/RecentProjects';
import NewTranscriptForm from '../components/NewTranscriptForm';
import { getCurrentUserSession, getCurrentUser } from '../backend/api';

const Dashboard = () => {

  const Navigate = useNavigate();

  useEffect(()=>{
    if(getCurrentUserSession() === false) {
      Navigate('/');
    } else {
      const user = getCurrentUser()
      const username = user["username"];
    }
  },[Navigate]);

  return (
    <div>
      <div className="h-screen flex justify-center">
          <div className="grid grid-cols-12">
            <div className="col-span-2">
              <SideNav/>
            </div>
            <div className="col-span-1"></div>
            <div className="justify-center col-span-8 h-screen">
              <div>
                <div>
                  <div className="flex flex-row">
                    <div><img src="logo.svg" alt="alt"  /></div>
                    <div className="content-center"><h2>Hi! I am SBS AI, your personal assistant :</h2></div>
                  </div>
                  <div className="header">
                    <p>How can I help you today?</p>
                  </div>
                  <div className="ml-5 mt-3 pb-5">
                    <ul className="list-disc">
                      <li>Drag and drop an audio file to generate an editable transcription</li>
                      <li>Drag and drop a rundown or script to convert it into a time stamped transcript</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                  <NewTranscriptForm></NewTranscriptForm>
              </div>
              <hr className="divider"></hr>
              <div>
                  <RecentProjects></RecentProjects>
              </div>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Dashboard