import { Modal, Box, Typography} from "@mui/material";

const DefaultModal = ({handleClose, open, errorMsg}) => {
    const style = {
        position: 'absolute',
        color: '#000',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return <Modal
                open={open}
                onClose={() => handleClose} 
                aria-labelledby="Delete transcript"
                aria-describedby="Delete transcript"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {errorMsg}
                        </Typography>
                        <Box onClick={handleClose}>Close</Box>
                    </Box>
                </Modal>
}

export default DefaultModal;