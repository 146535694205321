import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from './userpool';

export const authenticate=(Email,Password)=>{
    return new Promise((resolve,reject)=>{
        const user=new CognitoUser({
            Username:Email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:Email,
            Password
        });

        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                var accessToken = result.getAccessToken().getJwtToken();
                var idToken = result.getIdToken().getJwtToken();
                document.cookie = "idToken="+idToken;
                document.cookie = "accessToken="+accessToken;
                console.log("login successful");
                resolve(result);
            },
            onFailure:(err)=>{
                console.log("login failed",err);
                reject(err);
            }
        });
    });
};

export const logout = () => {
    const user = userpool.getCurrentUser();
    user.signOut();
    window.location.href = '/';
};

export const checkAuth = () => {
    const user = userpool.getCurrentUser();
    if (user === null) {
        return false;
    } else {
        return true;
    }
}
