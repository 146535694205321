import { FormControl, TextField, Modal, Box } from "@mui/material"
import { triggerForgotPassword } from "../backend/api";

const ResetPasswordModal = ({handleClose, open}) => {

    const style = {
        position: 'absolute',
        color: '#000',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleFormControl = async() =>{
        var username = document.getElementById("email").value;

        triggerForgotPassword(username)

        handleClose(username)
        await new Promise(r => setTimeout(r, 1000));
        window.location.href="/?action=verify&username=" + username
    }

    return (
        <Modal
                open={open}
                onClose={() => handleClose}
                aria-labelledby="Reset your password"
                aria-describedby="Reset your password"
                >
                    <Box sx={style}>
                        <FormControl>
                            <div>To reset your password please enter your email below and click on Submit.</div>
                            Email Address: <TextField id="email"></TextField>
                            <Box onClick={handleFormControl}>Submit</Box>
                        </FormControl>
                    </Box>
        </Modal>
    )
}

export default ResetPasswordModal;