import { useState } from 'react'
import { Box, Button, DialogTitle, IconButton, Dialog, DialogContent, DialogActions,  } from '@mui/material';
import { SentimentNeutral, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';
import { sendSentiment } from '../backend/api';

const FeelingsWidget = ({transcriptId, userId}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    var feeling = "";

    const updateButtonColors = (button, status) => {
        if (status === "selected") {
            button.style.background = "blue"
            button.style.color = "white"
        } else {
            button.style.background = "transparent"
            button.style.color = "black"
        }
    }

    const handleSentimentClick = (event) => {
        const buttonSatisfied = document.getElementById('sentiment-satisfied')
        const buttonNuetral = document.getElementById('sentiment-nuetral')
        const buttonUnsatisfied = document.getElementById('sentiment-unsatisfied')
        const buttonId = event.currentTarget.id

        if (buttonId === 'sentiment-satisfied') {
            feeling = "satisfied";
            updateButtonColors(buttonSatisfied, "selected")
            updateButtonColors(buttonNuetral, "unselected")
            updateButtonColors(buttonUnsatisfied, "unselected")
        } else if (buttonId === 'sentiment-nuetral') {
            feeling = "nuetral";
            updateButtonColors(buttonSatisfied, "unselected")
            updateButtonColors(buttonNuetral, "selected")
            updateButtonColors(buttonUnsatisfied, "unselected")
        } else if (buttonId === 'sentiment-unsatisfied') {
            feeling = "unsatisfied";
            updateButtonColors(buttonSatisfied, "unselected")
            updateButtonColors(buttonNuetral, "unselected")
            updateButtonColors(buttonUnsatisfied, "selected")
        }

        console.log(event.currentTarget.id)
        console.log(feeling)
    }

    const handleSubmit = async() => {
        if(feeling === "") {
            return;
        }
        const feelingsForm = document.getElementById('feelingsForm')
        const feelingsSubmitted = document.getElementById('feelingsSubmitted')
        const sentiment = {
            'userId': userId,
            'transcriptionId': transcriptId,
            'sentiment': feeling,
            'release': process.env.REACT_APP_RELEASE_ID
        }
        console.log(sentiment)
        sendSentiment(sentiment)
        feelingsForm.className = 'hidden'
        feelingsSubmitted.className = 'visible'
        await new Promise(r => setTimeout(r, 4000));
        setOpen(false)
    }

    return  (
        <div className="fixed bottom-4 right-4 z-99 border-solid border-">
            <Box>
                <Button onClick={handleOpen}>
                    Feedback
                </Button>
                <Dialog
                    open={open}
                    onClose={() => handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                        <DialogTitle>Feedback</DialogTitle>
                        <div id="feelingsForm">
                            <DialogContent>
                                        <div>We'd love to know how your session went. Just click on one of the buttons below and hit submit</div>
                                        <div className="p-2 flex w-full justify-center">
                                            <div className="w-1/3">
                                                <IconButton sx={{justifyContent: 'center'}} onClick={handleSentimentClick} id="sentiment-satisfied">
                                                    <SentimentVerySatisfied fontSize='large' titleAccess='Satisfied'></SentimentVerySatisfied>
                                                </IconButton>
                                            </div>
                                            <div className="w-1/3">
                                                <IconButton onClick={handleSentimentClick} id="sentiment-nuetral">
                                                    <SentimentNeutral fontSize='large' titleAccess='Neutral'></SentimentNeutral>
                                                </IconButton>
                                            </div>
                                            <div className="w-1/3">
                                                <IconButton onClick={handleSentimentClick} id="sentiment-unsatisfied">
                                                    <SentimentVeryDissatisfied fontSize='large' titleAccess='Unsatisfied'></SentimentVeryDissatisfied>
                                                </IconButton>
                                            </div>
                                        </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant='outlined' onClick={handleSubmit}>Submit</Button>
                                <Button variant='outlined' onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </div>
                        <div id="feelingsSubmitted" className="hidden">
                            <DialogContent>
                                <h2>Thanks for your submission</h2>
                            </DialogContent>
                        </div>
                </Dialog>
            </Box>
        </div>
    )
}

export default FeelingsWidget;