import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SlateTranscriptEditor from 'slate-transcript-editor';

import { createUpdateTranscript, fetchTranscript, getTranscript, getMp3Url, checkUserAuth, getUserId } from '../backend/api';
import SideNav from '../components/SideNav';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FeelingsWidget from '../components/FeelingsWidget';
import { Backdrop, Box, Typography, CircularProgress } from '@mui/material';
import ExportForm from '../components/ExportForm';
import DefaultModal from '../components/DefaultModal'

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Editor = () => {

    checkUserAuth()

    const [transcript, setTranscript] = useState({})
    const [transcriptPath, setTranscriptPath] = useState('')
    const [presignedUrl, setPresignedUrl] = useState('')
    const [searchParams] = useSearchParams();
    const [transcriptTitle, setTranscriptTitle] = useState('')
    const [user, setUser] = useState({})
    const [value, setValue] = useState(0);
    const [saveOpen, setSaveOpen] = useState(false)
    const transcriptId = searchParams.get('transcriptionId')
    const [defaultModalOpen, setDefaultModalOpen] = useState(false)
    const [defaultModalMsg, setDefaultModalMsg] = useState('')
    const [textDirection, setTextDirection] = useState('ltr')
    const [showSpeakers, setShowSpeakers] = useState(true)

    useEffect(() => {(async () => {
      try{
          const data = await fetchTranscript(transcriptId)
          console.log(data)
          const transObject = data;
          if ("message" in data) {
            setDefaultModalMsg("There was an error retrieving that Transcript")
            console.log(data["message"])
            setDefaultModalOpen(true)
          } else {
            setTextDirection(isRtl(transObject['Language']))
            setUser(getUserId())
            setTranscript(await getTranscript(transObject['TranscriptFile']))
            setTranscriptPath(transObject['TranscriptFile'])
            setPresignedUrl(await getMp3Url(process.env.REACT_APP_PROCESSED_MEDIA_KEY + transObject['MediaFile']))
            setTranscriptTitle(transObject['Title'])
          }
      } catch(error) {
        setDefaultModalMsg("There was an error retrieving that Transcript")
        setDefaultModalOpen(true)
        console.log(error)
      }
      })()
    }, [transcriptId])

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    const isRtl = (lang) => {
      const rtlLangs = ['ar-AE', 'ar-SA', 'he-IL', 'iw-IL'];
      var rtl = false
      console.log(lang)
      try {
        if (Array.isArray(JSON.parse(lang))) {
          console.log("It's an array")
          rtl = rtlLangs.some(r => lang.includes(r))
        }
      } catch (e) {
        console.log("Is not an array")
        if (rtlLangs.includes(lang)) {
          rtl = true
        }
      }

      console.log(rtl)

      if (rtl === true) {
        return 'rtl'
      } else {
        return 'ltr'
      }
    }

    const handleSaveEditor = async(data) => {
      console.log("Data: " + data)
      const transcriptObject = {
        "Type": "UPDATE",
        "transcriptObject": {
          "id": transcriptId,
          "transcriptBody": data
        },
        "UserId": user["username"]
      }
      setSaveOpen(true)
      const response = await createUpdateTranscript(transcriptObject)
      if (response["type"] === "SUCCESS") {
        setTranscript(await getTranscript(transcriptPath))
      } else {
        console.log(response)
      }
      console.log(response)
      setSaveOpen(false)
    }

    const handleDefaultModalClose = () => {
      setDefaultModalOpen(false);
      window.location.href = "/dashboard";
    }

    console.log(transcript)

    const GetTranscriptEditor = ({transcript}) => {
      try {
        if (transcript != null && Object.keys(transcript).length > 0){
          return <SlateTranscriptEditor
          mediaUrl={presignedUrl}
          handleSaveEditor={handleSaveEditor}// optional - function to handle when user clicks save btn in the UI
          transcriptData={transcript}
          autoSaveContentType="sbs"
          title={transcriptTitle}
          showTitle={true}
          dir={textDirection}
          showSpeakers={showSpeakers}
          />
        }
      } catch (error) {
        setDefaultModalMsg("There was an error retrieving that Transcript")
        setDefaultModalOpen(true)
        console.log(error)
      }
    }

    return(
        <div className="grid grid-cols-12 h-screen justify-center">
          <div className="col-span-2">
            <SideNav></SideNav>
          </div>
          <div className="col-span-10 justify-center">
            <div className="w-full flex justify-evenly">
            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab className="editorTab" label="Editor" {...a11yProps(0)} />
              <Tab className="editorTab" label="Export" {...a11yProps(1)} />
            </Tabs>
              <div>
              </div>
            </div>
            <CustomTabPanel value={value} index={0}>
              <GetTranscriptEditor transcript={transcript}></GetTranscriptEditor>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ExportForm transcriptId={transcriptId}></ExportForm>
            </CustomTabPanel>
          </div>
          <DefaultModal handleClose={handleDefaultModalClose} open={defaultModalOpen} errorMsg={defaultModalMsg}></DefaultModal>
          <FeelingsWidget transcriptId={transcriptId} userId={user}></FeelingsWidget>
          <Backdrop
            open={saveOpen}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <CircularProgress color="inherit" />
          </Backdrop>
        </div>
    )
}

export default Editor