import { S3Client, PutObjectCommand, GetObjectCommand } from "@aws-sdk/client-s3";
import { SQSClient, SendMessageCommand } from '@aws-sdk/client-sqs';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Buffer } from 'buffer';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
});

var currentUser = userPool.getCurrentUser();
var idToken = ""
let loginMap = {}

if (currentUser != null) {
    currentUser.getSession((err, session) => {
        if(err) {
            window.location.href = "/";
        } else if (!session.isValid()) {
            window.location.href = "/";
        } else {
            idToken = session.getIdToken().getJwtToken();
            loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken
        }
    })
}

export const getCurrentUser = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });

    return userPool.getCurrentUser();
}

export const getCurrentUserSession = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });
    
    var currentUser = userPool.getCurrentUser();

    if (currentUser != null) {
        currentUser.getSession((err, session) => {
            if (err) {
                console.log("false")
                return false;
            }

            if (session === undefined || session === null) {
                return false;
            }

            if (session.isValid()) {
                return true;
            }
        })
    } else {
        return false;
    }
}

export const triggerForgotPassword = (username) => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });

    console.log(username)
    const cognitoUser = new CognitoUser(
        {
            Username: username,
            Pool: userPool
        }
    )

    const promise = new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                alert("Mail Sent")
            },
            onFailure: function (err) {
                console.log(err)
            },
            inputVerificationCode()
            {
                window.myVar = setInterval(function(){
                    console.log('check');
                }, 10000);
                var verificationCode = document.getElementById('code').value;
                var newPassword = document.getElementById('fpass').value;
                cognitoUser.confirmPassword(verificationCode, newPassword, this);
            }
        });
    });
}

export const resetPassword = (verificationCode, newPassword, username) => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });

    const cognitoUser = new CognitoUser(
        {
            Username: username,
            Pool: userPool
        }
    )

    const promise = new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure: (err) => {
                console.log("onFailure:" + err);
                reject(err);
            },
            onSuccess: (res) =>  {
                console.log("onSuccess:");
                resolve();
            },
            undefined
        });
    });
    return promise;
}

export const getUserId = () => {
    if (currentUser != null) {
        return currentUser['username']
    }
}

export const uploadS3 = async(file, transcriptId, setSaveOpen, progressCallback) => {

    loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken
    const client = new S3Client({
        region: "ap-southeast-2",
        credentials: fromCognitoIdentityPool({
        clientConfig: { region: "ap-southeast-2" },
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            logins: loginMap
        }),
    });

    try {
        console.log(loginMap)
        const media_file_name = transcriptId+"_"+file['name'].replaceAll(" ", "_");
        const bucket = process.env.REACT_APP_TRANSCRIPTION_BUCKET
        const key = process.env.REACT_APP_UPLOAD_MEDIA_KEY + media_file_name

        const s3Input = {
            'Bucket': bucket,
            'Key': key,
            'Body': file
        };

        const command = new PutObjectCommand(s3Input)

        const url = await getSignedUrl(client, command, {expiresIn: 3600})

        setSaveOpen(true)
        return new Promise(function (resolve, reject) {

            const xhr = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                resolve(xhr)
                }
                else {
                reject(xhr)
                }
            }
            };

            if (progressCallback) {
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                var percentComplete = (e.loaded / file.size) * 100;
                progressCallback(percentComplete);
                }
            };
            }

            xhr.open("PUT", url);
            xhr.send(file);
        });
    } catch(error) {
        return {"type": "error", "message": "There was an error uploading your file"}
    }
}

export const uploadMedia = async(file, transcriptId) => {

    const uploadMediaKey = process.env.REACT_APP_UPLOAD_MEDIA_KEY

    //let loginMap = {}
    loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken + "23"

    const media_file_name = transcriptId+"_"+file['name'].replaceAll(" ", "_");

    const s3Input = {
        'Bucket': process.env.REACT_APP_TRANSCRIPTION_BUCKET,
        'Key': uploadMediaKey + media_file_name,
        'Body': file
    };

    const s3Command = new PutObjectCommand(s3Input)
    try {
        await(client.send(s3Command));
        return media_file_name;
    } catch (err) {
        console.log(err)
        deleteTranscript(transcriptId)
        return {"type": "error", "message": "There was a problem uploading your file. Please wait and try again later."}
    }
}

export const sendSqs = async (message, queue) => {

    console.log("Sending sqs")
    let loginMap = {}
    loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken
    const sqsClient = new SQSClient({
        region: "ap-southeast-2",
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: "ap-southeast-2" },
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            logins: loginMap
        }),
    })

    const sqsCommand = new SendMessageCommand({
        QueueUrl: queue,
        MessageBody: JSON.stringify(message)
    })

    console.log(sqsCommand)
    try {
        const sqsResponse = await(sqsClient.send(sqsCommand))
        return sqsResponse
    } catch (err) {
        console.log(err)
        return {"error": "There was an error communicating with the SQS endpoint"}
    }
}

export const createUpdateTranscript = async(transcriptObject) => {
    const url = process.env.REACT_APP_BASE_API_URL + "/put";
    console.log(transcriptObject)
    try{
        const request = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': idToken
            }),
            body: JSON.stringify(transcriptObject)
        });

        if (request["status"] !== 200) {
            return {"type": "ERROR", "message": "There was an error creating your transcript. Please wait and try again"}
        }
        const data = await request.json();
        return data;
    } catch (error) {
        console.log(error)
        return {"type": "error", "message": "There was an error creating your transcript. Please wait and try again."}
    }
}

export const deleteTranscript = async(transcriptId) => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });
    
    var currentUser = userPool.getCurrentUser();
    const url = process.env.REACT_APP_BASE_API_URL + "/delete"
    console.log(currentUser)
    try {
        const job = {"transcriptId": transcriptId, userId: currentUser["username"]}
        await fetch(url, {
            method: "post",
            headers: new Headers({
                'Authorization': idToken
            }),
            body: JSON.stringify(job)
        })
    } catch (error) {
        console.log(error)
    }
}

export const fetchTranscriptions = async () => {

    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    });

    var currentUser = userPool.getCurrentUser();

    const paramString = "type=all&userId="+currentUser["username"];
    const url = process.env.REACT_APP_BASE_API_URL + "/fetch?"+paramString;

    currentUser.getSession((err, session) => {
        if (!session.isValid()) {
            console.log("Session is invalid")
        }
        idToken = session.getIdToken().getJwtToken();
    })

    try{
        const request = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': idToken
            })
        });

        const data = await request.json();
        if (data)
        return data;
    } catch (error) {
        console.log(error)
        return {"type": "error", "message": "There was an error fetching your list of projects. If the problem persists, please log out and back in again."}
    }
}

export const fetchTranscript = async (transcriptId) => {

    const paramString = "type=single&transcriptionId="+transcriptId;
    const url = process.env.REACT_APP_BASE_API_URL + "/fetch?"+paramString;

    try{
        const request = await fetch(url, {
            cache: "reload",
            method: 'get',
            headers: new Headers({
                'Authorization': idToken
            }),
        });

        const data = await request.json();
        return data;
    } catch (error) {
        console.log(error)
        return {"type": "error", "message": "There was an issue fetching this transcript."}
    }
}

export const fetchLanguageMatrix = async() => {
    const url = process.env.REACT_APP_BASE_API_URL + "/languages"
    try{
        const request = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': idToken
            })
        });

        const data = await request.json();
        return data;
    } catch (error) {
        console.log(error)
        return {"type": "error", "message": "There was an error communicating with the server"}
    }
}

export const exportTranscripts = async(transcriptId, options) => {
    const url = process.env.REACT_APP_BASE_API_URL + "/export"
    const jobObject = {'transcriptId': transcriptId, 'options': options}
    try{
        const request = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': idToken
            }),
            body: JSON.stringify(jobObject)
        });

        const data = await request.json();
        return data;
    } catch (error) {
        console.log(error)
        return {"type": "error", "message": "There was an error exporting your transcript."}
    }
}

// Auth Functions

export const checkUserAuth = () => {
    if (currentUser != null) { 
        currentUser.getSession((err, session) => {
            if(err) {
                window.location.href = "/";
            } else if (!session.isValid()) {
                window.location.href = "/";
            }})
    } else {
        window.location.href = "/"
    }
}

// Editor Functions

export const getMp3Url = async (key) => {
    loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken
    const client = new S3Client({
        region: "ap-southeast-2",
        credentials: fromCognitoIdentityPool({
        clientConfig: { region: "ap-southeast-2" },
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            logins: loginMap
        }),
    });
    const fileParams = {
        "Bucket": process.env.REACT_APP_TRANSCRIPTION_BUCKET,
        "Key": key
    }

    const command = new GetObjectCommand(fileParams);
    const url = await getSignedUrl(client, command, { expiresIn: 43200 });
    return url;
}

export const getTranscript = async(e) => {

    loginMap['cognito-idp.' + process.env.REACT_APP_CLIENT_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID] = idToken
    const client = new S3Client({
        region: "ap-southeast-2",
        credentials: fromCognitoIdentityPool({
        clientConfig: { region: "ap-southeast-2" },
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            logins: loginMap
        }),
    });

    try {
        const getObjectInput = {
            Bucket: process.env.REACT_APP_TRANSCRIPTION_BUCKET,
            Key: e,
            ResponseCacheControl: "max-age=0"
        }

        const command = new GetObjectCommand(getObjectInput);
        const { Body } = await client.send(command);
        const text = await streamToString(Body);
        console.log(text)
        return text
    } catch (err) {
        return {"error": err}
    }
}

export const sendSentiment = async (sentiment) => {
    const url = process.env.REACT_APP_BASE_API_URL + "/sentiment"
    try{
        const request = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': idToken
            }),
            body: JSON.stringify(sentiment)
        });

        await request.json();
    } catch (error) {
        console.log(error)
    }

}

const streamAsyncIterator = async function* (stream) {
  // Get a lock on the stream
    const reader = stream.getReader();

    try {
        while (true) {
            // Read from the stream
            const {done, value} = await reader.read();
            // Exit if we're done
            if (done) return;
            // Else yield the chunk
            yield value;
        }
    }
    finally {
        reader.releaseLock();
    }
}

const streamToString = async(stream) =>{
  const chunks = []
  for await (const chunk of streamAsyncIterator(stream)) {
    chunks.push(chunk)
  }
  const responseBuffer = Buffer.concat(chunks)
  return JSON.parse(responseBuffer.toString())
}