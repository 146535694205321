import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Box, Button, TextField,Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { authenticate } from '../backend/authenticate';
import ResetPasswordModal from './ResetPasswordModal';
import { resetPassword } from '../backend/api';
import DefaultModal from './DefaultModal';

const LoginForm = () => {

  const fieldStyle = {
    width: 1,
    backgroundColor: "white",
    marginBottom: "5px",
    "&.Mui-focused": { color: "green" }
  }

  const Navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationErrMsg, setVerificationErrMsg] = useState('')
  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState('')
  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState('')
  const [loginErr,setLoginErr]=useState('');
  const [resetModalOpen, setResetModalOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [defaultModalOpen, setDefaultModalOpen] = useState(false)
  const [defaultModalMsg, setDefaultModalMsg] = useState('')

  const handleDefaultModalClose = () => { 
    setDefaultModalOpen(false);
  }

  const handleResetModalClose = (username) => { 
    setResetModalOpen(false)
  }
  const openResetModal = () => {setResetModalOpen(true)}

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
    if (formField === "verificationCode") {
      setVerificationCode(value);
    }
    if (formField === "newPassword") {
      setNewPassword(value);
    }
    if (formField === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === '' && password === '') {
        setEmailErr("Email is Required");
        setPasswordErr("Password is required")
        resolve({ email: "Email is Required", password: "Password is required" });
      }
      else if (email === '') {
        setEmailErr("Email is Required")
        resolve({ email: "Email is Required", password: "" });
      }
      else if (password === '') {
        setPasswordErr("Password is required")
        resolve({ email: "", password: "Password is required" });
      }
      else if (password.length < 6) {
        setPasswordErr("must be 6 character")
        resolve({ email: "", password: "must be 6 character" });
      }
      else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const verifyValidation = () => {
    return new Promise((resolve, reject) => {
      if (verificationCode === '' && newPassword === '') {
        setVerificationErrMsg("You need to enter a code")
        setNewPasswordErrMsg("Please enter a new password")
        resolve({ verificationCode: "A code is required"})
      } else if(newPassword !== confirmPassword) {
        setNewPasswordErrMsg("Your passwords do not match")
        resolve({newPassword: "Your passwords do not match", confirmPassword: "Your passwords do not match"})
      } else {
        resolve({ verificationCode: "", newPassword: "", confirmPassword: ""})
      }
    })
  }

  const handleClick = (event) => {
    event.preventDefault()
    setEmailErr("");
    setPasswordErr("");
    validation()
      .then((res) => {
        if (res.email === '' && res.password === '') {
          authenticate(email,password)
          .then((data)=>{
            setLoginErr('');
            Navigate('/dashboard');
          },(err)=>{
            console.log(err);
            setLoginErr(err.message)
          })
          .catch(err=>console.log(err))
        }
      }, err => console.log(err))
      .catch(err => console.log(err));
  }

  const handleVerificationClick = (event) => {
    event.preventDefault()
    setVerificationErrMsg("");
    verifyValidation().then(async (res) => {
      if (res.verificationCode === '' && res.newPassword === '' && res.confirmPassword === '') {
        res = resetPassword(verificationCode, newPassword, searchParams.get("username"))
        res
          .then((result) => {
            setDefaultModalMsg("Your password has been updated. Please return to the login page and try again")
            setDefaultModalOpen(true)
            window.location.href="/"
          })
          .catch((result) => {
            if (result["name"] === "LimitExceededException") {
              setDefaultModalMsg("There have been too many attempts from this location. Please wait a little while and try again")
            } else if (result["name" === "InvalidParameterException"]) {
              setDefaultModalMsg("Your password did not match the required settings. Please try again")
            }
            setDefaultModalOpen(true)
          })
      }
    })
  }

  const loginForm = (
    <div className="login">
      <form  onSubmit={handleClick}>
      <div className='w-full'>
        <div className="formfield">
          <TextField
            sx={fieldStyle}
            value={email}
            onChange={(e) => formInputChange("email", e.target.value)}
            label="Email"
            helperText={emailErr}
          />
        </div>
        <div className='formfield'>
          <TextField
            sx={fieldStyle}
            value={password}
            onChange={(e) => { formInputChange("password", e.target.value) }}
            type="password"
            label="Password"
            helperText={passwordErr}
          />
        </div>
        <div className='formfield'>
          <Button type='submit' variant='contained'>Login</Button>
        </div>
        <ResetPasswordModal open={resetModalOpen} handleClose={handleResetModalClose}></ResetPasswordModal>
        <Typography variant="body">{loginErr}</Typography>
        <Box onClick={openResetModal}>Forgot Password?</Box>
      </div>
      </form>
    </div>
  )

  const verifyForm = (
    <form  onSubmit={handleVerificationClick}>
    <div className="login">
      <DefaultModal handleClose={handleDefaultModalClose} open={defaultModalOpen} errorMsg={defaultModalMsg}></DefaultModal>
      <div className='w-full'>
        <h2>Reset your password</h2>
        <div className="mb-10">Please reset your password so that it matches the following rules
          <ul>
            <li>10 characters long (at least)</li>
            <li>1 number</li>
            <li>1 special character</li>
            <li>1 uppercase</li>
            <li>1 lowercase</li> 
          </ul> 
        </div>
        <div className="formfield">
          <TextField
            sx={fieldStyle}
            value={verificationCode}
            onChange={(e) => formInputChange("verificationCode", e.target.value)}
            label="Verification Code"
            helperText={verificationErrMsg}
          />
        </div>
        <div className='formfield'>
          <TextField
            sx={fieldStyle}
            value={newPassword}
            onChange={(e) => { formInputChange("newPassword", e.target.value) }}
            type="password"
            label="New Password"
            helperText={newPasswordErrMsg}
          />
        </div>
        <div className='formfield'>
          <TextField
            sx={fieldStyle}
            value={confirmPassword}
            onChange={(e) => { formInputChange("confirmPassword", e.target.value) }}
            type="password"
            label="Confirm Password"
            helperText={confirmPasswordErrMsg}
          />
        </div>
        <div className='formfield'>
          <Button type='submit' variant='contained'>Login</Button>
        </div>
      </div>
    </div>
    </form>
  )

  if (searchParams.get("action") === "verify") {
    return (
      verifyForm
    )
  } else {
    return  ( loginForm )
  }
}

export default LoginForm